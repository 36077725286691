import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', loadComponent: () => import('./pages/intro/intro.page').then((m) => m.IntroPage) },
  { path: 'tabs', loadChildren: () => import('./pages/tabs/tabs.routes').then((m) => m.tabRoutes) },
  {
    path: 'scan-card',
    loadComponent: () => import('./pages/modals/scan-card/scan-card.page').then((m) => m.ScanCardPage),
  },
];
