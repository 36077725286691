import { Component, OnInit } from '@angular/core';
import { IonApp, IonRouterOutlet, Platform, AlertController } from '@ionic/angular/standalone';
import { SwUpdate } from '@angular/service-worker';

import { TsAppVersion, versions } from '../_versions';

import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, IonApp, IonRouterOutlet],
})
export class AppComponent implements OnInit {
  //Version Information
  public readonly tsAppVersion: TsAppVersion;

  constructor(
    private platform: Platform,
    private swUpdate: SwUpdate,
    private alertController: AlertController,
  ) {
    this.tsAppVersion = versions;
    console.info('» Running version: ', this.tsAppVersion);
    this.initializeApp();
  }

  /**
   * OnInit
   */
  async ngOnInit() {
    // * Check for App New Version
    console.info('Update enabled?', this.swUpdate.isEnabled);
    if (this.swUpdate.isEnabled) {
      const hasUpdate = await this.swUpdate.checkForUpdate();
      console.info('Update available?', hasUpdate);
      if (hasUpdate) this.confirmUpdate();
    }
  }

  // * App Initialization
  initializeApp() {
    this.platform.ready();
  }

  /**
   * Shows Update Confirm Dialog
   */
  async confirmUpdate() {
    const alert = await this.alertController.create({
      header: 'MGMLojistas',
      subHeader: 'Nova versão encontrada!',
      message: `Atualizar para a nova versão?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Cancel update ...');
          },
        },
        {
          text: 'Atualizar',
          handler: () => {
            console.log('Updating...');
            window.location.reload();
          },
        },
      ],
    });

    await alert.present();
  }
}
